$font-stack: 'Montserrat', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-size: 14px;
$line-height: 24px;
$container-width: 1140px;
$grid-spacing: 8px;
$tablet-landscape-breakpoint: 1200px;
$tablet-portrait-breakpoint: 768px;
$phone-breakpoint: 480px;
$tablet-landscape-affix: tl;
$tablet-portrait-affix: tp;
$phone-affix: p;
$adjustment-max: 16;
$red: #bf3741;
$dark-red: #4a0f0f;
$light-grey: #a7a9ac;
$grey: #58595b;
$dark-grey: #333;
$background: #ddd;
$border-radius: 4px;