.grid {
    margin-top: -#{$grid-spacing};
    margin-bottom: -#{$grid-spacing};
}
.row {
    @extend .clearfix;
    margin-left: -#{$grid-spacing};
    margin-right: -#{$grid-spacing};
}
.column {
    display: block;
    width: 100%;
    float: left;
    padding: $grid-spacing;
    &.no-padding {
        padding: 0;
    }
    @each $column in $column-list {
        &.#{nth($column, 1)} {
            width: nth($column, 2);
        }
    }
    @each $column in $column-list {
        &.margin-#{nth($column, 1)} {
            margin-left: nth($column, 2);
        }
    }
    &.float-right {
        float: right;
        @each $column in $column-list {
            &.margin-#{nth($column, 1)} {
                margin-left: initial;
                margin-right: nth($column, 2);
            }
        }
    }
}
.grid-margin-top {
    margin-top: $grid-spacing * 2;
}
.grid-margin-bottom {
    margin-bottom: $grid-spacing * 2;
}
@media only screen and (max-width: $tablet-landscape-breakpoint) {
    @include breakpoint-columns($tablet-landscape-affix);
}
@media only screen and (max-width: $tablet-portrait-breakpoint) {
    @include breakpoint-columns($tablet-portrait-affix);
}
@media only screen and (max-width: $phone-breakpoint) {
    @include breakpoint-columns($phone-affix);
}