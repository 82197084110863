p + p {
    margin-top: 14px;
}
.normal {
    font-weight: normal;
}
.icon {
    text-align: center;
    font-size: 80px;
    line-height: 120px;
    color: $red;
    margin-bottom: 14px;
}
.button {
    @include animated;
    display: inline-block;
    padding: 12px 22px;
    font-weight: bold;
    color: $red;
    background-color: #fff;
    text-decoration: none;
    border-radius: $border-radius;
    font-size: 18px;
    &:hover {
        opacity: 0.75;
    }
}
section {
    padding: 80px 0;
    &#hero {
        overflow: hidden;
        padding: 160px 0;
        background: $red;
        background: -moz-linear-gradient(-45deg,  $red 0%, $dark-red 100%);
        background: -webkit-linear-gradient(-45deg,  $red 0%, $dark-red 100%);
        background: -o-linear-gradient(-45deg,  $red 0%, $dark-red 100%);
        background: -ms-linear-gradient(-45deg,  $red 0%, $dark-red 100%);
        background: linear-gradient(135deg,  $red 0%, $dark-red 100%);
        color: #fff;
        font-size: 22px;
        line-height: 30px;
        position: relative;
        .logo {
            max-width: 90%;
            position: relative;
            z-index: 1;
        }
        p {
            margin-top: 40px;
            position: relative;
            z-index: 1;
        }
        .screenshot {
            display: inline-block;
            position: absolute;
            bottom: 0;
            z-index: 0;
            opacity: 0.05;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100%;
            @media only screen and (max-width: $tablet-portrait-breakpoint) {
                display: none;
            }
        }
        .button {
            position: relative;
            z-index: 9999;
            margin-top: 40px;
        }
        @media only screen and (max-width: $tablet-portrait-breakpoint) {
            padding: 80px 0;
            font-size: 16px;
            line-height: 24px;
        }
    }
    &#intro {
        padding: 60px 0;
        background-color: $dark-grey;
        color: #fff;
        .header {
            padding-left: 60px;
            border-left: 10px solid $red;
            @media only screen and (max-width: $tablet-portrait-breakpoint) {
                border: none;
                padding: 0 20px;
            }
        }
    }
    &#features {
        .card {
            background-color: #fff;
            border-radius: $border-radius;
            padding: 40px;
            color: $grey;
            h2 {
                margin-bottom: 14px;
                text-align: center;
            }
        }
        @media only screen and (max-width: $tablet-landscape-breakpoint) {
            padding: 35px 0;
        }
    }
}
footer {
    padding: 25px 0;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: $dark-red;
}