* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
    &:before, &:after {
        @extend *;
        display: inline-block;
    }
}
html {
	height: 100%;
}
body {
    @extend html;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: $font-stack;
    font-size: $font-size;
    line-height: $line-height;
    background-color: $background;
    font-weight: normal;
    &.disable-scroll {
        overflow-y: hidden;
        -webkit-overflow-scrolling: none;
    }
}
a {
    @include animated;
    img {
        border: none;
        outline: none;
    }
}
img {
	max-width: 100%;
	height: auto;
}
.container {
    width: $container-width;
    margin: 0 auto;
    @media only screen and (max-width: $tablet-landscape-breakpoint) {
        width: 100%;
        padding: 0 (($tablet-landscape-breakpoint - $container-width) / 2);
    }
}
.clearfix:after {
	content: '';
	display: table;
	clear: both;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}
.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}